import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mt-8`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Terms And Condition" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            
              <h2> 1. Introduction </h2>
                
               <p> Welcome to Icon Adverts (“Company”, “we”, “our”, “us”)!</p>
               <p> These Terms of Service (“Terms”, “Terms of Service”) govern your use of our website located at www.ig2moon.com and mobile application (together or individually “Service”) operated by Icon Adverts.</p>
               <p> Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard and disclose information that results from your use of our website and mobile applications.</p>
               <p> Your agreement with us includes these Terms and our Privacy Policy (“Agreements”). You acknowledge that you have read and understood Agreements, and agree to be bound of them.</p>
               <p> If you do not agree with (or cannot comply with) Agreements, then you may not use the Service, please let us know by emailing at support@ig2moon.com so we can try to find a solution. These Terms apply to all visitors, users and others who wish to access or use Service.</p>
                
               <h2>About Ig2moon</h2>
                
               <p> This website is owned and operated by ICON ADVERTS. By visiting our website and accessing the information, resources, services, products, and tools we provide, you understand and agree to accept and adhere to the following terms and conditions as stated in this policy (hereafter referred to as ‘User Agreement’), along with the terms and conditions as stated in our site.</p>
                
               <h2> Privacy Policy</h2>
                
               <p>(please refer to the Privacy Policy section below for more information)</p>
               <p>We reserve the right to change this User Agreement from time to time without notice. You acknowledge and agree that it is your responsibility to review this User Agreement periodically to familiarize yourself with any modifications. Your continued use of this site after such modifications will constitute acknowledgement and agreement of the modified terms and conditions.</p>
                
               <h2> 2. Communications </h2>
                
               <p> By using our Service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing at support@ig2moon.com.</p>
                
               <h2> 3. Purchases </h2>
                
               <p> If you wish to purchase any product or service made available through Service (“Purchase”), you may be asked to supply certain information relevant to your Purchase including but not limited to, your credit or debit card number, the expiration date of your card, your billing address, and your shipping information.</p>
               <p> You represent and warrant that: (i) you have the legal right to use any card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.</p>
               <p> We may employ the use of third party services for the purpose of facilitating payment and the completion of Purchases. By submitting your information, you grant us the right to provide the information to these third parties subject to our Privacy Policy.</p>
               <p> We reserve the right to refuse or cancel your order at any time for reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons.</p>
                
               <h2> 4. Subscriptions </h2>
                
               <p> Some parts of Service are billed on a subscription basis ("Subscription(s)"). You will be billed in advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles will be set depending on the type of subscription plan you select when purchasing a Subscription.</p>
               <p> At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or ICON ADVERTS cancels it. You may cancel your Subscription renewal either through your online account management page or by contacting support@ig2moon.com customer support team.</p>
               <p> A valid payment method is required to process the payment for your subscription. You shall provide us with accurate and complete billing information that may include but not limited to full name, address, state, postal or zip code, telephone number, and a valid payment method information. By submitting such payment information, you automatically authorize ICON ADVERTS to charge all Subscription fees incurred through your account to any such payment instruments.</p>
               <p> Should automatic billing fail to occur for any reason, ICON ADVERTS reserves the right to terminate your access to the Service with immediate effect.</p>
                
               <h2> 5. Free Service </h2>
                
               <p> We may, at its sole discretion, offer a Subscription with a free service for a limited period of time ("Free Service").</p>
               <p> You may be required to enter your billing information in order to sign up for Free Service.</p>
               <p> At any time and without notice, ICON ADVERTS reserves the right to (i) modify Terms of Service of Free Service offer, or (ii) cancel such Free Service offer.</p>
                
               <h2> 6. Engagement Round </h2>
                
               <p> We may use the third-party app to support the engagement round as well as our current chat rooms. We may also use cookies as per the requirements of our platform. You can refer to the privacy policy for more details.</p>
               <p> You may be required to enter your Telegram username and Instagram handle information in order to join the rounds.</p>
                
               <h2> 7. Fee Changes </h2>
                
               <p> ICON ADVERTS, in its sole discretion and at any time, may modify Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.</p>
               <p> Your continued use of Service after Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.</p>
                
               <h2> 8. Refunds </h2>
                
               <p> We issue refunds for Contracts within 7 days of the original purchase of the first time usage. We, Icon Adverts are committed to providing the best service possible, at all times. Within our services, we do offer refunds under these policies:</p>
               <ul>
                   <li>1.	System failed to provide service or services during a calendar week.</li>
                   <li>2.	Accidentally purchased the wrong subscription package.</li>
                   <li>3.	Unexpected double payment following the transaction.</li>
                   <li>4.	If our services work as promised but then you decide that you no longer wish to continue:</li>
                   <li>A refund will NOT be possible as there is no issue with the services and every package that has been purchased is a monthly subscription. If a member does not remember to cancel their subscription, a refund can not be possible regardless of the reasons.</li>
                   <li>5.	Expecting something that is not written on the website:</li>
               </ul>
               <p> We will NOT refund the payment if the member in question did not clearly read and then expect certain features that were not described and written on the website.</p>
               <p> After the refund request is validated and approved by our admin, a refund can only be given within 7 DAYS after the transaction is done.</p>
               <p> Any transaction fee of the refund will be deducted from the total amount of refund.</p>
               <p> Any form of Scam and Defamation will be reported under the applicable law and block you from ever using our services and network in the future.</p>
               <p> We, Icon adverts holds the complete power in case any situation arises which is not mentioned in the terms. Refunds will be finalised solely on the discretion of Icon Adverts.</p>
                
               <h2> 9. Content </h2>
                
               <p> Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material (“Content”). You are responsible for Content that you post on or through Service, including its legality, reliability, and appropriateness.</p>
               <p> By posting Content on or through Service, You represent and warrant that: (i) Content is yours (you own it) and/or you have the right to use it and the right to grant us the rights and license as provided in these Terms, and (ii) that the posting of your Content on or through Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity. We reserve the right to terminate the account of anyone found to be infringing on a copyright.</p>
               <p> You retain any and all of your rights to any Content you submit, post or display on or through Service and you are responsible for protecting those rights. We take no responsibility and assume no liability for Content you or any third party posts on or through Service. However, by posting Content using Service you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through Service. You agree that this license includes the right for us to make your Content available to other users of Service, who may also use your Content subject to these Terms.</p>
               <p> ICON ADVERTS has the right but not the obligation to monitor and edit all Content provided by users.</p>
               <p> In addition, Content found on or through this Service are the property of ICON ADVERTS or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.</p>
                
               <h2> 10. Prohibited Uses </h2>
                
               <p> You may use Service only for lawful purposes and in accordance with Terms.</p>
               <p> You agree not to use Service:</p>
               <ul>
                   <li>a.	In any way that violates any applicable national or international law or regulation.</li>
                   <li>b.	For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.</li>
                   <li>c.	To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.</li>
                   <li>d.	To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.</li>
                   <li>e.	In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.</li>
                   <li>f.	To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of Service, or which, as determined by us, may harm or offend Company or users of Service or expose them to liability.</li>
               </ul>
               <p> Additionally, you agree not to:</p>
               <ul>
                   <li>a.	Use Service in any manner that could disable, overburden, damage, or impair Service or interfere with any other party’s use of Service, including their ability to engage in real time activities through Service.</li>
                   <li>b.	Use any robot, spider, or other automatic device, process, or means to access Service for any purpose, including monitoring or copying any of the material on Service.</li>
                   <li>c.	Use any manual process to monitor or copy any of the material on Service or for any other unauthorized purpose without our prior written consent.</li>
                   <li>d.	Use any device, software, or routine that interferes with the proper working of Service.</li>
                   <li>e.	Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.</li>
                   <li>f.	Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the server on which Service is stored, or any server, computer, or database connected to Service.</li>
                   <li>g.	Attack Service via a denial-of-service attack or a distributed denial-of-service attack.</li>
                   <li>h.	Take any action that may damage or falsify Company rating.</li>
                   <li>i.	Otherwise attempt to interfere with the proper working of Service.</li>
               </ul>
                
               <h2> 11. Analytics </h2>
                
               <p> We may use third-party Service Providers to monitor and analyze the use of our Service. Please check our privacy policy about how we collect and process Analytics data.</p>
                
               <h2> 12. No Use by Minors </h2>
                
               <p> Service is intended only for access and use by individuals at least fifteen (15) years old. By accessing or using Service, you warrant and represent that you are at least fifteen (15) years of age and with the full authority, right, and capacity to enter into this agreement and abide by all of the terms and conditions of Terms. If you are not at least fifteen (15) years old, you are prohibited from both the access and usage of Service.</p>
               <p> Regarding privacy please read thoroughly, acknowledge to our Privacy Policy.</p>
                
               <h2> 13. Accounts </h2>
                
               <p> When you create an account with us, you guarantee that you are above the age of 15, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on Service.</p>
               <p> You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
               <p> You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.</p>
               <p> We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.</p>
                
               <h2> 14. Intellectual Property </h2>
                
               <p> Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of ICON ADVERTS and its licensors. Service is protected by copyright, trademark, and other laws of national and foreign countries. Our trademarks may not be used in connection with any product or service without the prior written consent of ICON ADVERTS.</p>
                
               <h2> 15. Copyright Policy </h2>
                
               <p> We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on Service infringes on the copyright or other intellectual property rights (“Infringement”) of any person or entity.</p>
               <p> If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement, please submit your claim via email to support@ig2moon.com, with the subject line: “Copyright Infringement” and include in your claim a detailed description of the alleged Infringement as detailed below, under “DMCA Notice and Procedure for Copyright Infringement Claims”</p>
               <p> You may be held accountable for damages (including costs and attorneys’ fees) for misrepresentation or bad-faith claims on the infringement of any Content found on and/or through Service on your copyright.</p>
                
               <h2> 16. Error Reporting and Feedback </h2>
                
               <p>You may provide us either directly at support@ig2moon.com or via third party sites and tools with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Service (“Feedback”). You acknowledge and agree that: (i) you shall not retain, acquire or assert any intellectual property right or other right, title or interest in or to the Feedback; (ii) Company may have development ideas similar to the Feedback; (iii) Feedback does not contain confidential information or proprietary information from you or any third party; and (iv) Company is not under any obligation of confidentiality with respect to the Feedback. In the event the transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, you grant Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify, create derivative works, publish, distribute and commercialize) Feedback in any manner and for any purpose.</p>
                
               <h2> 17. Links to Other Web Sites </h2>
                
               <p> Our Service may contain links to third party web sites or services that are not owned or controlled by ICON ADVERTS.</p>
               <p> ICON ADVERTS has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites.</p>
               <p> YOU ACKNOWLEDGE AND AGREE THAT COMPANY SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES.</p>
               <p> WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.</p>
                
               <h2> 18. Disclaimer of Warranty </h2>
                
               <p> THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.</p>
               <p> NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>
               <p> COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</p>
               <p> THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
                
               <h2> 19. Withdrawal </h2>
                
               <p> You have the right to withdraw if you earned commission in the last three months. The minimum payout amount is INR 1000, and the maximum amount is INR 10000. The payout can only be requested once per 30 days. The request will be processed every Monday and be sent via our authorised payment providers.</p>
                
               <h2> 20. Limitation of Liability </h2>
                
               <p> EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS’ FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.</p>
                
               <h2> 21. Termination </h2>
                
               <p> We may terminate or suspend your account and bar access to Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of Terms.</p>
               <p> If you wish to terminate your account, you may simply discontinue using our Service.</p>
               <p> All provisions of Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
                
               <h2> 22. Governing Law </h2>
                
               <p> This website is controlled by ICON ADVERTS from our offices located in India. It can be accessed by most countries around the world. As each country has laws that may differ from those of India, by accessing our website, you agree that the statutes and laws of India, without regard to the conflict of laws and the United Nations Convention on the International Sales of Goods, will apply to all matters relating to the use of this website and the purchase of any products or services through this site. These Terms shall be governed and construed in accordance with the laws of Indonesia, which governing law applies to agreement without regard to its conflict of law provisions.</p>
               <p> Furthermore, any action to enforce this User Agreement shall be brought in the district court, or high court, or supreme court, located in India. You hereby agree to personal jurisdiction by such courts, and waive any jurisdictional, venue, or inconvenient forum objections to such courts.</p>
                
               <h2> 23. Changes to Service </h2>
                
               <p> We reserve the right to withdraw or amend our Service, and any service or material we provide via Service, in our sole discretion without notice. We will not be liable if for any reason all or any part of Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts of Service, or the entire Service, to users, including registered users.</p>
                
               <h2> 24. Amendments to Terms </h2>
                
               <p> We may amend Terms at any time by posting the amended terms on this site. It is your responsibility to review these Terms periodically.</p>
               <p> Your continued use of the Platform following the posting of revised Terms means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you.</p>
               <p> By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use Service.</p>
                
               <h2> 25. Acknowledgement </h2>
                
               <p> BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.</p>
                
               <h2> 26. Contact Us </h2>
                
               <p> If you have any questions or comments about these our Terms of Service as outlined above, you can Contact us by emailing support@ig2moon.com or via mail to:</p>
               <p> ICON ADVERTS</p>
               <p> INDIA</p>
                
               <h1> Disclaimer </h1>
                
                
               <h2> Website Disclaimer </h2>
                
               <p> The information provided by Icon Adverts (“Company”, “we”, “our”, “us”) on www.ig2moon.com (the “Site”) is for general informational purposes only. All information on the Site is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site.</p>
               <p> UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR USE OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE IS SOLELY AT YOUR OWN RISK.</p>
                
               <h2> Earning Disclaimer </h2>
                
               <p> Any income or earnings statements are estimates of income potential only, and there is no assurance that your earnings will match the figures presented, which are given as examples.</p>
               <p> All products and services of our company are for educational and informational purposes only. Thus, nothing on our website or otherwise disseminated by Ig2moon in conjunction with it should be taken as medical, legal, accounting or other such advice. Use caution and seek the advice of qualified professionals. Check with a qualified accountant, lawyer or professional advisor, before acting on this or any information.</p>
               <p> Your use of the website, participation in Member or Affiliate programs confirm your consent and agreement that Icon Adverts is not responsible for the success or failure of your business decisions relating to any information presented by our company, or our company programs, products and/or services. Information related to income or financial success provided on any website owned or operated by the Seller is simply information and is not directly related to any MLM or business opportunity of any kind. We at Icon Adverts are not responsible for any errors or commissions contained in any disseminated material and are not liable for any loss incurred as a result of using the material in any way. The intent is merely to provide useful information, products, and services, some of which we may be compensated for.</p>
               <p> AS WITH ANY BUSINESS ENDEAVOR, WE DO NOT WARRANT AN INHERENT RISK OF LOSS OF CAPITAL AND THERE IS NO GUARANTEE THAT YOU WILL EARN ANY MONEY. PLEASE READ ALL AGREEMENTS, NOTICES AND DISCLAIMERS BEFORE PURCHASING ANYTHING. AFFILIATE PROGRAMS WITH PRODUCTS OF DIGITAL NATURE AND EVENT SALES HAVE UNKNOWN RISKS INVOLVED, AND ARE NOT SUITABLE FOR EVERYONE. MAKING DECISIONS BASED ON ANY INFORMATION PRESENTED IN OUR PROGRAMS, PRODUCTS, SERVICES OR ON OUR WEBSITE, SHOULD BE DONE ONLY WITH THE KNOWLEDGE THAT YOU COULD EXPERIENCE SIGNIFICANT LOSSES, OR MAKE NO MONEY AT ALL. ONLY RISK CAPITAL SHOULD BE USED.</p>
                
               <h2> External Links Disclaimer </h2>
                
               <p>The Site may contain (or you may be sent through the Site) links to other websites or content belonging to or originating from third-parties or links to websites and features. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability or completeness by us.</p>
               <p>WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.</p>
                
               <h2> Testimonials Disclaimer </h2>
                
               <p> The Site may contain testimonials by users of our products and/or services. These testimonials reflect the real-life experiences and opinions of such users. However, the experiences are personal to those particular users, and may not necessarily be representative of all users of our products and/or services. We do not claim, and you should not assume that all users will have the same experiences.</p>
               <p> YOUR INDIVIDUAL RESULTS MAY VARY.</p>
               <p> The testimonials on the Site are submitted in various forms such as text, audio and/or video, and are reviewed by us before being posted. They appear on the Site verbatim as given by the users, except for the correction of grammar or typing errors. Some testimonials may have been shortened for the sake of brevity, where the full testimonial contained extraneous information not relevant to the general public.</p>
               <p> The views and opinions contained in the testimonials belong solely to the individual user and do not reflect our views and opinions.</p>
                
               <h2> Errors and Omissions Disclaimer </h2>
                
               <p> While we have made every attempt to ensure that the information contained in this site has been obtained from reliable sources, Icon Adverts is not responsible for any errors or omissions or for the results obtained from the use of this information. All information in this site is provided “as is”, with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability, and fitness for a particular purpose.</p>
               <p> In no event will Icon Adverts, its related partnerships or corporations, or the partners, agents or employees thereof be liable to you or anyone else for any decision made or action taken in reliance on the information in this Site or for any consequential, special or similar damages, even if advised of the possibility of such damages.</p>
                
               <h2> Logos and Trademarks Disclaimer </h2>
                
               <p> All logos and trademarks of third parties referenced on www.ig2moon.com are the trademarks and logos of their respective owners. Any inclusion of such trademarks or logos does not imply or constitute any approval, endorsement or sponsorship of Icon Adverts by such owners.</p>
               <p> NOTICE OF CHANGE: The contents of this page may change over time. We reserve the right to change, alter and update the content as necessary.</p>
 
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
