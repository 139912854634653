import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Pricing from "components/pricing/ThreePlans";
// import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import Footer from "components/footers/MiniCenteredFooter.js";
function App({user,setLoginUser}) {
  
	return (
    <><AnimationRevealPage>
      <Header />
      <Pricing user={user} setLoginUser={setLoginUser}/>
      <Footer />
    </AnimationRevealPage>
    </>
	)
}

export default App
