import React,{useState,useEffect} from 'react'
import axios from 'axios'
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import '../styles/newStyles.css'
import { useHistory } from 'react-router-dom';
import {CgLogOut} from 'react-icons/cg'
import HashLoader from "react-spinners/HashLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Threeplan from './pricing/ThreePlans';

function InstaBot({user,setLoginUser,activeUser,setActiveUser,userCookie,setUserCookie,subscribed,setSubscribed}) {
  
    const history = useHistory()
    const listOfRounds=["9:30am","12:30pm","3:30pm","6:30pm","9:30pm","12:30am"]
    const [Allrounds,setAllRounds]=useState([...listOfRounds]);
    const [liveRound,setLiveRound]=useState({
      roundNo:null,
      roundTime:null,
    })
    const [UserDetails,setUserDetails]=useState({
           "response":{ 
             "data":{
                "form_data":{
                    "username":"",
                    "biography":"" 
                }
            }}
    });
     const [errMsg,seterrMsg]=useState(null)
     const [loading,setLoading]=useState(false)

      useEffect(() => {
         const token = localStorage.getItem('token')
        if (!token) {
          localStorage.removeItem('token')
          history.push('/login')
        } else {	
          setLoading(true)
          axios.post(`https://api.ig2moon.com/checkUser`,{token:token})
          .then(function (response) {
              if(response.data.name !== "JWSSignatureVerificationFailed"){
                setUserCookie(response.data.userCookie)
                setLoginUser(response.data)
              axios.post(`https://api.ig2moon.com/get_profile/`,{cookieArray: response.data.userCookie})
              .then(function (response) {
                  if(response.data.type==="invalid-json"){
                    setLoading(false)
                    setUserCookie("")
                  }else{
                    setLoading(false)
                    setUserDetails({response})
                    setActiveUser({response}.response.data.form_data.username)
                  }
              })
              .catch(function (error) {
                setLoading(false)
                  setUserCookie("")
              })
              }else{
                localStorage.removeItem('token')
                history.push('/login')
              }
          })
          .catch(function (error) {
              setLoading(false)
              console.log(error);
          })
        axios.post(`https://api.ig2moon.com/blacklistStatus`,{userHandle:activeUser,blacklistCount:0})
        .then(function (response) {
          if(response.data.blacklisted >= 3){
            blacklistNotify()
          }
        })
        .catch(function (error) {
          console.log(error)
        })
        }// eslint-disable-next-line
      }, []); 

      useEffect(()=>{
        if(user.subscriptionExpiry){
          fetch("https://worldtimeapi.org/api/timezone/Asia/Kolkata")
          .then(response => response.json())
          .then(data => {
            var gTimeinMillis = data.unixtime*1000;
            if(user.subscriptionExpiry){
              if(user.subscriptionExpiry<=data.unixtime){
                setSubscribed(false)
              }else{
                setSubscribed(true)
              }
            }else{
              setSubscribed(false)
            }

            function startClock() {
              gTimeinMillis = gTimeinMillis+1000;
              var dObj = new Date(gTimeinMillis);
              var currentHours = dObj.getHours();
              var currentMinutes = dObj.getMinutes();
              var currentSeconds = dObj.getSeconds();
              currentHours = (currentHours < 10 ? "0" : "") + currentHours;
              currentMinutes = (currentMinutes < 10 ? "0" : "") + currentMinutes;
              currentSeconds = (currentSeconds < 10 ? "0" : "") + currentSeconds;
              var IntTime=parseInt(currentHours.toString(10)+currentMinutes.toString(10)+currentSeconds.toString(10))
              
              if(IntTime>=920000 && IntTime<930000){
              // if(IntTime>=0 && IntTime<235959){
                setLiveRound({
                  roundNo:"1",
                  roundTime:"9:30am",
                })
                setAllRounds([...listOfRounds].filter((r)=>r!=="9:30am"))
              }
              else if(IntTime>=122000 && IntTime<123000){
                setLiveRound({
                  roundNo:"2",
                  roundTime:"12:30pm",
                })
                setAllRounds([...listOfRounds].filter((r)=>r!=="12:30pm"))
              }
              else if(IntTime>=152000 && IntTime<153000){
                setLiveRound({
                  roundNo:"3",
                  roundTime:"3:30pm",
                })
                setAllRounds([...listOfRounds].filter((r)=>r!=="3:30pm"))
      
              }
              else if(IntTime>=182000 && IntTime<183000){
                setLiveRound({
                  roundNo:"4",
                  roundTime:"6:30pm",
                })
                setAllRounds([...listOfRounds].filter((r)=>r!=="6:30pm"))
      
              }
              else if(IntTime>=212000 && IntTime<213000){
                setLiveRound({
                  roundNo:"5",
                  roundTime:"9:30pm",
                })
                setAllRounds([...listOfRounds].filter((r)=>r!=="9:30pm"))
      
              }
              // else if(IntTime>=2000 && IntTime<10000){
                else if(IntTime>=2000 && IntTime<3000){
                setLiveRound({
                  roundNo:"6",
                  roundTime:"12:30am",
                })
                setAllRounds([...listOfRounds].filter((r)=>r!=="12:30am"))
      
              }
              else{
                setLiveRound({
                  roundNo:null,
                  roundTime:null,
                })
                setAllRounds(listOfRounds)
              }
              
              setTimeout(startClock, 1000);
            }
            startClock();
          });
        }else{
          setSubscribed(false)

        }
        // eslint-disable-next-line
      },[user])

      const blacklistNotify = ()=>toast.error('You are blacklisted', {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    function cookieHandler(){
      setLoading(true)
      let cookieInput=document.getElementById("cookieInput").value;
      let cookieJson ="";
      let cookieArr=[]
      try {
        cookieJson=JSON.parse(cookieInput);
        var C_STR="";
          cookieArr=cookieJson.map((cookie) => {
          let cName = cookie.name
          let cValue = cookie.value
          let cArr= cName+"="+cValue+";"
          C_STR+=cArr
          return C_STR
        })
      }catch (e) {
        cookieArr=[cookieInput]
      }
        axios.post(`https://api.ig2moon.com/get_profile`,{cookieArray: cookieArr[cookieArr.length - 1]})
        .then(function (response) {
            // handle success
            setLoading(false)

            if(response.data.type==="invalid-json"){
              seterrMsg("Invalid or expired cookie")
              setUserCookie("")
            }else{
              setUserDetails({response})
              setActiveUser({response}.response.data.form_data.username)
              document.getElementById("modal-container").style.display = "none";
              setUserCookie(response.data.userCookie)
            }
           
              axios.post("https://api.ig2moon.com/postCookie", [user,cookieArr[cookieArr.length - 1],{response}.response.data.form_data.username])
                .then(res => {})
                
        })
        .catch(function (error) {
            console.log(error)
        })
  } 
    function logout(){
      setLoading(true)
          setLoginUser({})
          setActiveUser("")
          setUserCookie(null)
          setUserDetails({
            "response":{ 
              "data":{
                 "form_data":{
                     "username":"",
                     "biography":"" 
                 }
             }}
          })
        localStorage.removeItem('token')
        window.location.replace('/#/login')
    }
   
    async function pushHandler(){
      setLoading(true)
      await axios.post(`https://api.ig2moon.com/registerStatus`,{roundNum:parseInt(liveRound.roundNo),userHandle:activeUser})
            .then(function (response) {
                setLoading(false)
                if(response.data.message === "" || response.data.message === "Invalid Round"){
                    history.push("/home")
                }
                else if (response.data.message === "NotInList" || response.data.message === "InList" || response.data.message === "Engagement Group Full" ){
                  window.location.replace(`/#/round/${liveRound.roundNo}`)
                }else{
                  history.push("/home")
                }

                

            })
            .catch(function (error) {
                alert(error);
            })
    }
    return (
      <>
       {loading ? (<div className='HashLoaderClass'><HashLoader color={"A336D7"} loading={loading} size={30} /></div>)
        : (<AnimationRevealPage>
          <div className='InstaBot'>
       
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <button className="logout-button" title='Logout' onClick ={() => logout()} ><CgLogOut className='SVG_DOOR' onClick ={() => logout()} /></button>
          {subscribed ?
            <>
                {userCookie!=="" ?
                  <>
                    <br/>
                    <br/>
                    <h1 className="BG_HEADING"><span>Welcome, @{activeUser}</span></h1> 
                    <h5>Bio</h5> 
                    <p>{UserDetails.response.data.form_data.biography}</p>
                    <br/>
                    <div>
                  <div >
                    <h1 className='Rounds'>Current Round:</h1>
                    <br/>
                    <div className='RoundBtns'>
                    {liveRound.roundNo!==null?<button className='ActiveRound' onClick={()=>pushHandler()} >{`${liveRound.roundTime}`}</button>:"No Live Rounds"}
                  
                    </div>
                    <br/>
        
                    <h1 className='Rounds'>List of Rounds:</h1>
                    <br/>
        
                    <div className='RoundBtns'>
                    {Allrounds.map((round)=><button  className='disabledRound' disabled>{round}</button>)}
                    </div>
                  </div>
        
                    </div>
                </>
                :<>
                  <div id ="modal-container" className='overLaypop'>
                  <div className="modal-content">
                  <h1 className="BG_HEADING"><span>Enter Cookie</span></h1> 
                    <input type="text" id="cookieInput" placeholder='Enter IG cookie' required/>
                    <br/>
                    {errMsg && <><p className='ERRMSG'>{errMsg} <br/><a style={{color:"#6415ff"}} href='#/'>Need Help?</a></p><br/></>}
                    <button className='ActiveRound' onClick={()=>cookieHandler()}>Submit</button>
                  <br /><br />
                  <h2>Do you need any help?</h2>
                  <br />
                  <button className='ActiveRound' onClick={()=>window.open("https://youtu.be/M79i8HZN17k", "_blank")}>Android User</button>
                  <br /><br />
                  <button className='ActiveRound' onClick={()=>window.open("https://youtu.be/M79i8HZN17k", "_blank")}>IOS User</button>
                    </div>
                  </div>
                 </>
                }
            
            </>
          :<>
            <Threeplan user={user} setLoginUser={setLoginUser}/>
          </>  
        }
        </div></AnimationRevealPage>)
        }


      </>
      
    )
}

export default InstaBot