import React,{useState,useEffect} from 'react'
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import illustration from "images/login-illustration.svg";
import logo from "images/logo.svg";

import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import HashLoader from "react-spinners/HashLoader";

import 'react-toastify/dist/ReactToastify.css';
const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;



const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 text-2xl px-2  inline-block my-3`;
 
function Login() {
	const [name, setName] = useState('')
	const [password, setPassword] = useState('')
	const history = useHistory()
  const [errMsg,seterrMsg]=useState(null)
  const [loading,setLoading]=useState(false)
  const [verified,setVerified]=useState(null)

  let logoLinkUrl = "#"
  let illustrationImageSrc = illustration
  let headingText = "Sign In To "

  let submitButtonText = "Sign In"
  let SubmitButtonIcon = LoginIcon
  let forgotPasswordUrl = "#/forgot"
  let signupUrl = "#/register"


	async function loginUser(event) {
		event.preventDefault()
    setLoading(true)
		const response = await fetch('https://api.ig2moon.com/loginCheck', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				name,
				password,
			}),
		})

		const data = await response.json()
		if (data.user && data.status === "ok") {
			localStorage.setItem('token', data.token)
      setLoading(false)
      history.push('/home')
		}else if(data.status === "not_verified"){
      setLoading(false)
      setVerified(false)
    }
    else {
      setLoading(false)
      seterrMsg(data.error)
    }
	}

  function verifyOTP(){
    setLoading(true)

    var code = document.getElementById('OTP').value
    axios.post(`https://api.ig2moon.com/verifyOTP`,{OTP:code,user_name:name})
          .then(function (response) {
            setLoading(false)
             if (response.data.status === "ok"){
               setVerified(true)
               seterrMsg("OTP Verfied, please login")
              //  loginUser(e)
             }else{
              seterrMsg("Invalid OTP")
             }
          })
          .catch(function (error) {
              console.log(error);
          })
  }

  useEffect(()=>{ 
    const token = localStorage.getItem('token')
    setLoading(true)
    if (token) {
        axios.post(`https://api.ig2moon.com/checkUser`,{token:token})
          .then(function (response) {
              
              if(response.data.name !== "JWSSignatureVerificationFailed"){
                  setLoading(false)
                  window.location.replace('/#/home')
              }else{
                  setLoading(false)
                  localStorage.removeItem('token')
              }
          })
          .catch(function (error) {
              console.log(error);
          })
      
    }else{
      setLoading(false)
    }
  },[])

	return (
    <AnimationRevealPage disabled>

    {loading ? (<div className='HashLoaderClass'><HashLoader color={"A336D7"} loading={loading} size={30} /></div>):
    verified===false?
    <>
      <div id ="modal-container" className='overLaypop'>
      <div className="modal-content">
      <h1 className="BG_HEADING"><span>Enter OTP</span></h1> 
        <input type="text" id="OTP" placeholder='Enter OTP' required/>
        <br/>            
        {errMsg && <><p className='ERRMSG'>{errMsg}<br/></p></>}
        <button className='ActiveRound' onClick={()=>verifyOTP()}>Submit</button>
        {/* closeHandler() */}
        <br/>
        <br/>
        <h2>We have mailed you the OTP on your email!</h2>
        </div>
      </div>
      
    </>
    :        
    <Container>
      <Content>
        <MainContainer>
          <LogoLink href={logoLinkUrl}>
            <LogoImage src={logo} />
          </LogoLink>
          <MainContent>
            <Heading>{headingText}<HighlightedText>IG2MOON</HighlightedText></Heading>
            <FormContainer>
              <Form onSubmit={loginUser}>
                <Input type="text" placeholder="Username" value={name} onChange={(e) => setName(e.target.value)} required/>
                <Input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
                <SubmitButton type="submit">
                  <SubmitButtonIcon className="icon" />
                  <span className="text">{submitButtonText}</span>
                </SubmitButton>
              </Form>
              <p tw="mt-6 text-xs text-gray-600 text-center">
                
              </p>
              {errMsg && <><p className='ERRMSG'>{errMsg}<br/></p></>}
              <p tw="mt-8 text-sm text-gray-600 text-center">
                Dont have an account?{" "}
                <a href={signupUrl} tw="border-b border-gray-500 border-dotted">
                  Sign Up
                </a>
              </p><p tw="mt-8 text-sm text-gray-600 text-center">
                <a href={forgotPasswordUrl} tw="border-b border-gray-500 border-dotted">
                  Forgot Password
                </a>
              </p>
            </FormContainer>
          </MainContent>
        </MainContainer>
        <IllustrationContainer>
          <IllustrationImage imageSrc={illustrationImageSrc} />
        </IllustrationContainer>
      </Content>
    </Container>
    
    }


    
      
        
      
      
    </AnimationRevealPage>
    
	)
}
export default Login