import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Testimonial from "components/testimonials/TwoColumnWithImage.js";
import WithStatsAndImage2Features from "components/features/TwoColSingleFeatureWithStats2.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import DownloadApp from "components/cta/DownloadApp.js";
import GetStarted from "components/cta/GetStarted";
import FAQ from "components/faqs/SingleCol.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import HeroImg from "../images/prototype-illustration.png";

export default () => {
  const Subheading = tw.span`tracking-wider text-4xl font-medium `;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 text-4xl px-4 transform -skew-x-12 inline-block mt-3`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  // const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      
      <Hero
        heading={<>Socialization Platform<br /><HighlightedText>Standard Social Media Pods</HighlightedText></>}
        description="We at IG2MOON help you in boosting your social media engagement. ( i.e., by providing you with the best platform to socialize with people just “LIKE” you, hassle free and efficiently."
        imageSrc = {HeroImg}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Let’s Get Started!"
        // watchVideoButtonText="Meet The Chefs"
      />
      <FeatureStats/>
      <WithStatsAndImage2Features/>
      <DownloadApp
        text={<>People Around You Are Socializing Using The <HighlightedTextInverse>IG2MOON Mobile App.</HighlightedTextInverse></>}
        />
      <Testimonial
        subheading=""
        heading={<>Customers <HighlightedText>Love Us.</HighlightedText></>}
      />
      <GetStarted/>
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "What should be the best plan to choose?",
            answer:"There is no best plan. The best plan is the one which meets the requirements of the user. So for every person the best plan may vary."
          },
          {
            question: "Can we use UPI payment?",
            answer:
              "Yes, we accept UPI payment. You can checkout with your desired plans to know the details of all the UPI options available."
          },
          {
            question: "How can I get resolution for my queries?",
            answer:
              "You can email us your queries or any issues you are facing. The resolution for the same will be provided to you within 24-48 hrs."
          },
          {
            question: "Is there any free package?",
            answer:
              "No, we don't offer any free plans and people don't value free things. Our basic plan's cost is less than a star bucks coffee which makes it very competitive and economical for anyone who wishes to grow their Instagram account."
          },
          {
            question: "Who all can use our services?",
            answer:
              "Anyone who is on Instagram can use our products but most of our services are used by influencers and entrepreneurs who wish to grow their social media presence."
          }
          
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
}
