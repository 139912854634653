import React,{useState,useEffect} from 'react'
import {useLocation } from 'react-router-dom';
import axios from 'axios'
import '../styles/newStyles.css'
import {GoSignOut} from 'react-icons/go'
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Header from "components/headers/light.js";
import { useHistory } from 'react-router-dom'
import HashLoader from "react-spinners/HashLoader";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-4.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob4 } from "images/svg-decorator-blob-2.svg";
import styled from "styled-components";
import tw from "twin.macro";
// import { faL } from '@fortawesome/free-solid-svg-icons';
import {FaLongArrowAltRight} from 'react-icons/fa'

// var falseCount=0;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
 ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;
const DecoratorBlob3 = tw(SvgDecoratorBlob3)`absolute w-32 top-0 left-auto -z-10 text-primary-500 opacity-25 transform -translate-x-2/3 `;
const DecoratorBlob4 = tw(SvgDecoratorBlob4)`absolute w-32 bottom-0 right-0 -z-10 text-pink-500 opacity-15 transform translate-x-2/3 translate-y-8`;


function Round({user,activeUser,setActiveUser,userCookie,setUserCookie,setLoginUser,subscribed,setSubscribed}) {
    // https://api.ig2moon.com
    const history = useHistory()
    const [participant,setParticipant]=useState([])
    // let [participantNotLiked,setParticipantNotLiked]=useState([])
    const [roundEnd,setroundEnd]=useState(false)
    const [errMsg,seterrMsg]=useState(null)
    const [loading,setLoading]=useState(false)
    const [RoundTiming,setRoundTiming]=useState('')
    
    const location=useLocation();
    
    const path=location.pathname.split('/')[2]||1;
    if(path <= 0 ||  path >= 7){
        localStorage.removeItem('token')
        history.push('/login')
    }
    // IMP States
    const [enrolled,setenrolled]=useState(false)
    const [roundStarted,setroundStarted]=useState(false)
    
    const [date,setDate]=useState(0)
    
    const notify = (roundNotification) => toast.error(roundNotification, {
        position: "top-left",
        autoClose: 60000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    const RoundNotify = (roundNotification) => toast(roundNotification, {
        position: "top-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    // //setLoading(true)
        

    useEffect(()=>{
        setLoading(true)
        const token = localStorage.getItem('token')
		  if (!token) {
				localStorage.removeItem('token')
                setLoading(false)
				history.push('/login')
                // alert("1")
		} else {
                //setLoading(true)
                if(path <= 0 ||  path >= 7){
                    localStorage.removeItem('token')
                    setLoading(false)
                    history.push('/login')
                }else {
                    setLoading(true)
                    axios.post(`https://api.ig2moon.com/checkUser`,{token:token})
                    .then(function (response) {
                        
                        // console.log(response.data)
                        if(response.data.name !== "JWSSignatureVerificationFailed"){
                            setLoading(false)
                            setUserCookie(response.data.userCookie)
                            setLoginUser(response.data)
                            setActiveUser(response.data.ig_handle)

                            setLoading(true)
                            axios.post(`https://api.ig2moon.com/registerStatus`,{roundNum:parseInt(path),userHandle:response.data.ig_handle})
                            .then(function (response1) {
                                setLoading(true)

                                setRoundTiming(response1.data.startTime+"-"+response1.data.endTime)
                                if(response1.data.status === "ok"){
                                    if(response1.data.message === "InList"){
                                        setenrolled(true)
                                        
                                        setLoading(false)
                                        fetch("https://worldtimeapi.org/api/timezone/Asia/Kolkata")
                                        .then(response2 => response2.json())
                                        .then(data => {
                                            //setLoading(false)
                                            var gTimeinMillis = data.unixtime*1000;
                                            gTimeinMillis = gTimeinMillis+1000;
                                            
                                            var dObj = new Date(gTimeinMillis);
                                                // var time=dObj.getHours();
                                            // console.log(time);
                                            var currentHours = dObj.getHours();
                                            var currentMinutes = dObj.getMinutes();
                                            // // Operating System Clock Seconds
                                            var currentSeconds = dObj.getSeconds();
                                            // // Adding 0 if Minutes & Seconds is More or Less than 10
                                            currentHours = (currentHours < 10 ? "0" : "") + currentHours;
                                            currentMinutes = (currentMinutes < 10 ? "0" : "") + currentMinutes;
                                            currentSeconds = (currentSeconds < 10 ? "0" : "") + currentSeconds;
                                    
                                            var time = currentHours + ":" + currentMinutes + ":" + currentSeconds;
                                            // document.getElementById("roundTime").innerHTML = time;
                                            var intTime=parseInt(time.replaceAll(':',''))
                                            
                                            var starttimeRound  = response1.data.startTime.split(":")
                                            var endtimeRound  = response1.data.endTime.split(":")

                                            var intStartTimeDB=parseInt(starttimeRound.toString().replaceAll(',',''))
                                            var intEndTimeDB=parseInt(endtimeRound.toString().replaceAll(',',''))
                                            
                                            if(intStartTimeDB<intTime && intEndTimeDB > intTime){
                                                startRound()
                                            }
                        
                                            });
                                            
                                    }else{
                                        setLoading(false)
                                        setenrolled(false)

                                    }
                                }else{
                                    setLoading(false)
                                    setenrolled(false)

                                }
                            })
                            .catch(function (error1) {
                                console.log(error1);
                            })
                            
                        }else{
                            localStorage.removeItem('token')
                            setLoading(false)
                            history.push('/login')
                            // alert("2")
                        }
                    })
                    .catch(function (error) {
                        // handle error 
                        console.log(error);
                    })
                    //setLoading(true)
                    fetch("https://worldtimeapi.org/api/timezone/Asia/Kolkata")
                    .then(response => response.json())
                    .then(data => {
                        //setLoading(false)
                        var gTimeinMillis = data.unixtime*1000;
                        setDate(data.day_of_year)
                        function startClock() {
                        gTimeinMillis = gTimeinMillis+1000;
                        
                        var dObj = new Date(gTimeinMillis);
                            // var time=dObj.getHours();
                        // console.log(time);
                        var currentHours = dObj.getHours();
                        var currentMinutes = dObj.getMinutes();
                        // // Operating System Clock Seconds
                        var currentSeconds = dObj.getSeconds();
                        // // Adding 0 if Minutes & Seconds is More or Less than 10
                        currentHours = (currentHours < 10 ? "0" : "") + currentHours;
                        currentMinutes = (currentMinutes < 10 ? "0" : "") + currentMinutes;
                        currentSeconds = (currentSeconds < 10 ? "0" : "") + currentSeconds;
                
                        var time = currentHours + ":" + currentMinutes + ":" + currentSeconds;
                        // document.getElementById("roundTime").innerHTML = time;
                        var IntTime=parseInt(currentHours.toString(10)+currentMinutes.toString(10)+currentSeconds.toString(10))
    
                        //   if-else
                        switch (parseInt(path)) {
                            case 1:
                                if(IntTime===93005){
                                    startRound()
                                }
                                if(IntTime===101000){
                                    warningFlash()
                                }
                                if(IntTime===102000){
                                    evaluatehandler(1)
                                }
                                if(IntTime===103000){
                                    evaluatehandler(0)
                                }
                                break;
                            case 2:
                                if(IntTime===123005){
                                    startRound()
                                }
                                if(IntTime===131000){
                                    warningFlash()
                                }
                                if(IntTime===132000){
                                    evaluatehandler(1)
                                }
                                if(IntTime===133000){
                                    evaluatehandler(0)
                                }
                                break;
                            case 3:
                                if(IntTime===153005){
                                    startRound()
                                }
                                if(IntTime===161000){
                                    warningFlash()
                                }
                                if(IntTime===162000){
                                    evaluatehandler(1)
                                }
                                if(IntTime===163000){
                                    evaluatehandler(0)
                                }
                                break;
                            case 4:
                                if(IntTime===183005){
                                    startRound()
                                }
                                if(IntTime===191000){
                                    warningFlash()
                                }
                                if(IntTime===192000){
                                    evaluatehandler(1)
                                }
                                if(IntTime===193000){
                                    evaluatehandler(0)
                                }
                                break;
                            case 5:
                                // if(IntTime===213005){
                                //     startRound()
                                // }
                                // if(IntTime===221000){
                                //     warningFlash()
                                // }
                                // if(IntTime===222000){
                                //     evaluatehandler(1)
                                // }
                                // if(IntTime===223000){
                                //     evaluatehandler(0)
                                // }
                                break;
                            case 6:
                                if(IntTime===3005){
                                    startRound()
                                }
                                if(IntTime===11000){
                                    warningFlash()
                                }
                                if(IntTime===12000){
                                    evaluatehandler(1)
                                }
                                if(IntTime===13000){
                                    evaluatehandler(0)
                                }
                                break;
                         
                            default:
                                break
                        
                        }
                        setTimeout(startClock, 1000);
                        }
                        startClock();
                    });
                }
        }
    },[])


    useEffect(() => {
    
        const token = localStorage.getItem('token')
   
        if (token) {
         
            axios.post(`https://api.ig2moon.com/blacklistStatus`,{userHandle:activeUser,blacklistCount:0,currentDate:date})
            .then(function (response) {
              
                if(response.data.blacklisted >= 3){
                    window.location.replace("/#/login")                    
                }
            })
            .catch(function (error) {
                console.log(error)
            })   
            axios.post(`https://api.ig2moon.com/registerStatus`,{roundNum:parseInt(path),userHandle:activeUser})
            .then(function (response) {
               
                if(response.data.message === "" ){
                    setenrolled(false)
                }else if (response.data.message === "Invalid Round"){
                    setenrolled(false)
                    history.push("/home")
                }

                else if(response.data.message === "NotInList"){
                    fetch("https://worldtimeapi.org/api/timezone/Asia/Kolkata")
                    .then(response1 => response1.json())
                    .then(data => {
                        var gTimeinMillis = data.unixtime*1000;
                        var dObj = new Date(gTimeinMillis);

                        var currentHours = dObj.getHours();
                        var currentMinutes = dObj.getMinutes();
                        var currentSeconds = dObj.getSeconds();

                   
                        currentHours = (currentHours < 10 ? "0" : "") + currentHours;
                        currentMinutes = (currentMinutes < 10 ? "0" : "") + currentMinutes;
                        currentSeconds = (currentSeconds < 10 ? "0" : "") + currentSeconds;
                
                        var time = currentHours + ":" + currentMinutes + ":" + currentSeconds;
                       
                        var intTime=parseInt(time.replaceAll(':',''))

                        var starttimeRound  = response.data.startTime.split(":")
                        var endtimeRound  = response.data.endTime.split(":")

                        var intStartTimeDB=parseInt(starttimeRound.toString().replaceAll(',',''))
                        var intEndTimeDB=parseInt(endtimeRound.toString().replaceAll(',',''))

                        if((starttimeRound[0]*3600 + starttimeRound[1]*60 + starttimeRound)*1000 - 60*10*1000< (currentHours*3600 + currentMinutes*60 + currentSeconds)*1000 || intStartTimeDB < intTime){
                        // if(intStartTimeDB-1000 > intTime || intStartTimeDB < intTime){
                            console.log("In If");
                            history.push("/home")
                        }
                        // intstart time 200000
               
                    });
                    setenrolled(false)
                }
                else if ( response.data.message === "InList" ){
                    setLoading(true)

                    fetch("https://worldtimeapi.org/api/timezone/Asia/Kolkata")
                    .then(response1 => response1.json())
                    .then(data => {
                        var gTimeinMillis = data.unixtime*1000;
                        var dObj = new Date(gTimeinMillis);

                        var currentHours = dObj.getHours();
                        var currentMinutes = dObj.getMinutes();
                        var currentSeconds = dObj.getSeconds();

                        currentHours = (currentHours < 10 ? "0" : "") + currentHours;
                        currentMinutes = (currentMinutes < 10 ? "0" : "") + currentMinutes;
                        currentSeconds = (currentSeconds < 10 ? "0" : "") + currentSeconds;
                
                        var time = currentHours + ":" + currentMinutes + ":" + currentSeconds;
                        var intTime=parseInt(time.replaceAll(':',''))

                        var starttimeRound  = response.data.startTime.split(":")
                        var endtimeRound  = response.data.endTime.split(":")

                        var intStartTimeDB=parseInt(starttimeRound.toString().replaceAll(',',''))
                        var intEndTimeDB=parseInt(endtimeRound.toString().replaceAll(',',''))

                        // (starttimeRound[0]*3600 + starttimeRound[1]*60 + starttimeRound)*1000 - 60*10*1000< (currentHours*3600 + currentMinutes*60 + currentSeconds)*1000 
                        if((starttimeRound[0]*3600 + starttimeRound[1]*60 + starttimeRound)*1000 - 60*10*1000 >= (currentHours*3600 + currentMinutes*60 + currentSeconds)*1000 || (endtimeRound[0]*3600 + endtimeRound[1]*60 + endtimeRound)*1000 + 60*10*1000 <= (currentHours*3600 + currentMinutes*60 + currentSeconds)*1000){
                        // if(intStartTimeDB-1000 >= intTime || intEndTimeDB+1000 <= intTime){
                            history.push("/home")
                        }
                    });
                    setenrolled(true)
                }

                else{
                    setenrolled(false)
                }
            })
            .catch(function (error) {
                // handle error
                alert(error);
            })
        }// eslint-disable-next-line
    }, [date]);

    async function startRound(){
        setLoading(true)
        await axios.post(`https://api.ig2moon.com/userList`,{roundNum:parseInt(path),entry:0,userHandle: activeUser})
        .then(function (response) {
            
            if(response.data.participantList == "INVALID"){
                setLoading(false)
                localStorage.removeItem('token')
                history.push('/login')
            }else{
                const token = localStorage.getItem('token')
                axios.post(`https://api.ig2moon.com/checkUser`,{token:token})
                    .then(function (response1) {
                        
                        if(response.data.name !== "JWSSignatureVerificationFailed"){
                            startRoundLaterMethod(response,response1.data.ig_handle)
                            setLoading(false)
                        }else{
                            setLoading(false)
                            localStorage.removeItem('token')
                            history.push('/login')
                        }
                    })
                    .catch(function (error) {
                        // handle error 
                        console.log(error);
                    })
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    }
    async function startRoundLaterMethod(response,a){
        setLoading(true)
        await axios.post(`https://api.ig2moon.com/userList`,{entry:2, userHandle: a})
        .then(function (responseNew) {
            
            if(responseNew.data.length === 0){
                var userPostArr = response.data.participantList
                var filteredArr = userPostArr.filter((u)=>{
                    u.hasLiked=false
                    return u.ig_handle !== activeUser
                })
                setLoading(true)
    
                if(response.data.listFull){
                    var sendArr = []
                    while(sendArr.length !== response.data.maxParticipants){
                        let index = Math.floor(Math.random() * filteredArr.length)
                        sendArr.push(filteredArr[index])
                        filteredArr.splice(index, 1)
                    }
                    setterMethod(sendArr)
                    setLoading(false)
    
                }else{
                    setLoading(false)
                    setterMethod(filteredArr) 
                }
            }else if(responseNew.data === "NoUserFound"){
            }
            else {
                setterMethod(responseNew.data)
            }
            setLoading(false)
        })
        .catch(function (error) {
                console.log(error)
        })
    }
    async function setterMethod(val){
        setLoading(true)
        await axios.post(`https://api.ig2moon.com/userList`,{entry:1, entryArray:val, userHandle: activeUser})
        .then(function (response) {
            setLoading(false)
        })
        .catch(function (error) {
            setLoading(false)
            console.log(error)
        }) 
        setroundStarted(true)
        RoundNotify("The Round Started!")
        setParticipant(val)
    }

    function warningFlash(){
        notify("10 mins remaining!!!")
        // alert()
    } 

    async function evaluatehandler(valueInt){
        setLoading(true)
        await axios.post(`https://api.ig2moon.com/userList`,{entry:2, userHandle: activeUser})
        .then(function (response) {
            if(response.data === "NoUserFound"){
            }else {
                evaluateUpdater(response.data,valueInt)
            }
        })
        .catch(function (error) {
                console.log(error)
        })
    }
    async function evaluateUpdater(participant,valueInt){
        for(let i=0;i<participant.length;i++){
            await axios.post(`https://api.ig2moon.com/userPost/${participant[i].postLink.split('/p/')[1]}`,{userCookie:userCookie})
            .then(function  (response) {
                // handle success
                if(response.data[1]){
                    participant[i].hasLiked=true
                    // console.log(participant[i])
                } 
                })
            .catch(function (error) {
                console.log(error)
            })  
        }
        var participantsCopy = participant.filter((pc)=>pc.hasLiked === false)
        console.log(participantsCopy);
        if(valueInt === 0){
            axios.post(`https://api.ig2moon.com/userList`,{entry:1, entryArray:participantsCopy, userHandle: activeUser})
            .then(function (response) {
            })
            .catch(function (error) {
                console.log(error)
            }) 
        }

        setLoading(false)

        endRound(participantsCopy,valueInt)
        
    }

    useEffect(() => {
        if(participant.length>=2){
            // blacklist user
            axios.post(`https://api.ig2moon.com/blacklistStatus`,{userHandle:activeUser,blacklistCount:1,currentDate:date})
            .then(function (response) {
            })
            .catch(function (error) {
                console.log(error)
            })
        }else{
            // cleanup
        }// eslint-disable-next-line
    }, [roundEnd]);
    
    function endRound(participantsCopy,valueInt){
        ////setLoading(false)
        setLoading(true)
        if(valueInt){
            if(participantsCopy.length>=1){
                setParticipant(participantsCopy)
            }else{
                setParticipant(participantsCopy)
                endRoundComplete()
            } 
            setLoading(false)
        }else{
            if(participantsCopy.length>=1){
                    axios.post(`https://api.ig2moon.com/blacklistStatus`,{userHandle:user.ig_handle, blacklistCount:1, currentDate:date})
                    .then(function (response) {
                    })
                    .catch(function (error) {
                    })
                    setLoading(false)
                    setTimeout(()=>{
                        notify("You did not complete the like events!")
                    },500)
            }
            setTimeout(()=>{
                endRoundComplete()
            },5000)
            
        }
        

        
    }

    function endRoundComplete(){
        clearRound(path)
        setTimeout(()=>{
            RoundNotify("Thankyou, the round ended!")
        },100)
        setTimeout(()=>{
            setroundEnd(true)
            setroundStarted(false)
            history.push("/home")
        },5000)
    }
    
    function clearRound(path){
        setLoading(true)

        // roundNum:parseInt(path)
        axios.post(`https://api.ig2moon.com/clearRound`,{roundNum:parseInt(path), userHandle: activeUser})
            .then(function (response) {
                setLoading(false)

                history.push('/home')
            })
            .catch(function (error) {
                console.log(error)
            })
    }
    
    function postUrlHandler(){
        setLoading(true)
        let postURL=document.getElementById("postInput").value.replaceAll(' ','')
        axios.post(`https://api.ig2moon.com/postLinkValid/${postURL.split('/p/')[1]}`,{userCookie:userCookie,roundNum:parseInt(path),userHandle:activeUser})
        .then(function (response) {
                // handle success
                setLoading(false)
                if(response.data==="Post does not exist"){
                    setenrolled(false)
                    seterrMsg("Post does not exist")
                }else{
                    if(user.userPost !== ""){
                    axios.post(`https://api.ig2moon.com/blacklistStatus`,{userHandle:user.ig_handle,blacklistCount:1,currentDate:date})
                    .then(function (response) {
                        // preRoundBlacklistHandler
                        axios.post(`https://api.ig2moon.com/preRoundBlacklistHandler`,{userHandle:user.ig_handle})
                            .then(function (response1) {
                            
                            })
                            .catch(function (error1) {
                                    console.log(error1)
                            })

                    })
                    .catch(function (error) {
                            console.log(error)
                    })
                    }
                    setenrolled(true)
                    document.getElementById("modal-container2").style.display = "none";
                }
        })
        .catch(function (error) {
                console.log(error)
        })
        
    }

    async function unEnrollHandler(){
        setLoading(true)
        await axios.post(`https://api.ig2moon.com/unEnroll`,{userHandle:activeUser,roundNum:parseInt(path)})
        .then(function (response) {
            // handle success
            
            if (roundStarted){
                axios.post(`https://api.ig2moon.com/blacklistStatus`,{userHandle:activeUser,blacklistCount:1,currentDate:date})
                .then(function (response) {
                })
                .catch(function (error) {
                    console.log(error)
                })
            }
            setenrolled(false)
        })
        .catch(function (error) {
            // handle error
            alert(error);
        })
        setLoading(false)

        history.push("/home")
                
    }
    return (
        loading ? (<div className='HashLoaderClass'><HashLoader color={"A336D7"} loading={loading} size={30} /></div>):
        <AnimationRevealPage>
        
        {
            (<div className="RoundScreen">
            {!enrolled &&
            
            
            <div id ="modal-container2" className='overLaypop'>
              <div className="modal-content">

                <h1 className="BG_HEADING"><span>Enter Post URL</span></h1> 
                <input id="postInput" placeholder='Enter Post URL' required/>
                <br/>
                {errMsg && <><p className='ERRMSG'>{errMsg} <br/><a style={{color:"#6415ff"}} href='#/'>Need Help?</a></p><br/></>}
                <button className='ActiveRound' onClick={()=>postUrlHandler()}>Enroll</button>
              </div>
            </div>
            }

            {enrolled && 
            <>
              {!roundStarted &&  <button title="Unenroll" className="logout-button" onClick={()=>unEnrollHandler()}><GoSignOut className='SVG_DOOR' onClick ={() => unEnrollHandler()} /></button>}
                <h1 className="BG_HEADING"><span>ROUND - {path} </span></h1> <br/>
                <h1 style={{textAlign:"center",fontWeight:"bolder"}}>{RoundTiming}</h1>
                <br/>
                {/* {activeUser} */}
                {!roundStarted && 
                    <>                    <h5 style={{textAlign:"center",fontSize:"2rem",textDecoration:"none"}}>Please wait till the round starts</h5>

                    <p className="roundmsg" >
                        <br/>
                    <HashLoader style={{textAlign:"center"}} color="#A336D7" size="35x"/>
                    <br/>
                    <br/>

                        <p className='roundmsgcontent'>
                        <h5 style={{textAlign:"justify"}}>Round Instructions:-</h5>
                        {/* There will be 6 rounds conducted per day which will be at an interval of every 3 hours starting from 9:30 am in the Morning till 12:30 am in the midnight. <br /> */}

                        <FaLongArrowAltRight style={{display:"inline",color:"#6415ff"}}/> Preparation Time will be maximum of 15 minutes before the round time. <br />
                        {/* , in which you can submit the link for the post that you want to gain engagement on. <br /> */}

                        {/* For eg. If you are joining the round at 3:30 pm, at 3:15 pm the preparation time will start. Any content posted before 3:25 pm will be allowed in the round. The round will begin at exact 3:30 pm and participants will get time till 4 pm to engage with other participants. <br /> */}

                        <FaLongArrowAltRight style={{display:"inline",color:"#6415ff"}}/> Participants are required to engage with all the posts appearing in the round. <br />
                        <FaLongArrowAltRight style={{display:"inline",color:"#6415ff"}}/> Failure to do so would amount to a warning from the app and you will be provided with the list of participants that you did not engage with. <br />

                        <FaLongArrowAltRight style={{display:"inline",color:"#6415ff"}}/> If you still fail to engage with them, you will be issued a warning.<br /> 
                        <FaLongArrowAltRight style={{display:"inline",color:"#6415ff"}}/> If you get 3 continuous warnings, you will be banned from the rounds for 48 hrs. <br />

                        <FaLongArrowAltRight style={{display:"inline",color:"#6415ff"}}/> Ban removals before completion of ban time will be done only in genuine cases only. For the same contact Administration<br />
                        {/* In case after joining the round, due to some unforeseen circumstances you are unable to complete the round, you can put up your request to remove the ban and provide specific reasons as to your non engagement. <br /> */}
                        
                        </p>
                    
                    </p>
                    </>
                }
                {/* <button onClick={()=>startRound()}>Start Round</button> */}
                {/* <p id='roundTime'></p> */}

                {roundStarted && <ol>
                    
                {participant && participant.map(function(user){
                    return(
                        <div className='participantsList'>
                            <div className='AvDiv'>
                                <div className='AvtaarIMGDIV'>
                                    <img className="AvtaarIMG" width={"75px"} src={`https://avatars.dicebear.com/api/bottts/${user.ig_handle}.svg`} alt={user.ig_handle+"_avatar"}/>
                                </div>
                                <p>{user.ig_handle}</p>
                            </div>
                            <a className='ActiveRound engaged' rel="noreferrer" target='_blank' href={user.postLink}>Engage</a>
                        </div>
                    )}
                    )
                }

                {/* <button onClick={notify}>Warn</button> */}
                {/* <button onClick={()=>warningFlash()}>Warn</button> */}
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

                <div style={{display:"flex",justifyContent:"center"}}><button onClick={()=>evaluatehandler(1)} className="ActiveRound" style={{}}>Evaluate</button></div>
                {/* <button onClick={()=>evaluatehandler(0)} >Evaluate 0 </button>
                <button onClick={()=>endRoundComplete()}>End Round</button>
                <button onClick={()=>clearRound()}>Clear Round</button> */}
                
                </ol>}
                
            </>
         
            }
            </div>)
        }    
        <DecoratorBlob1 />
        <DecoratorBlob2 />
        <DecoratorBlob3 />
        <DecoratorBlob4 />
        </AnimationRevealPage>
        
    )
}

export default Round
