import React, {useEffect} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob } from "images/svg-decorator-blob-6.svg";
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios"
import { useHistory } from 'react-router-dom';
import logo from "../../images/twitter-icon.png";
const dotenv = require('dotenv').config()
const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlansContainer = tw.div`flex justify-between flex-col lg:flex-row items-center lg:items-stretch relative`;
const Plan = styled.div`
  ${tw`w-full max-w-sm mt-16 lg:mr-8 lg:last:mr-0 text-center px-8 rounded-lg shadow relative pt-2 text-gray-900 bg-white flex flex-col`}
  .planHighlight {
    ${tw`rounded-t-lg absolute top-0 inset-x-0 h-2`}
  }

  ${props =>
    props.featured &&
    css`
      background: rgb(100,21,255);
      background: linear-gradient(135deg, rgba(100,21,255,1) 0%, rgba(128,64,252,1) 100%);
background: rgb(85,60,154);
background: linear-gradient(135deg, rgba(85,60,154,1) 0%, rgba(128,90,213,1) 100%);
background: rgb(76,81,191);
background: linear-gradient(135deg, rgba(76,81,191,1) 0%, rgba(102,126,234,1) 100%);
      ${tw`bg-primary-500 text-gray-100`}
      .planHighlight {
        ${tw`hidden`}
      }
      .duration {
        ${tw`text-gray-200!`}
      }
      ${PlanFeatures} {
        ${tw`border-indigo-500`}
      }
      .feature:not(.mainFeature) {
        ${tw`text-gray-300!`}
      }
      ${BuyNowButton} {
        ${tw`bg-gray-100 text-primary-500 hocus:bg-gray-300 hocus:text-primary-800`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col uppercase leading-relaxed py-8`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .duration {
    ${tw`text-gray-500 font-bold tracking-widest`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 border-t-2 border-b-2 flex-1`}
  .feature {
    ${tw`mt-5 first:mt-0 font-medium`}
    &:not(.mainFeature) {
      ${tw`text-gray-600`}
    }
  }
  .mainFeature {
    ${tw`text-xl font-bold tracking-wide`}
  }
`;

const PlanAction = tw.div`px-4 sm:px-8 xl:px-16 py-8`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full uppercase tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;

const DecoratorBlob = styled(SvgDecoratorBlob)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-1/2 translate-y-1/2`}
`;
function Threeplan({user,setLoginUser}){
  const history = useHistory()
  useEffect(()=>{
      const token = localStorage.getItem('token')
      if (!token) {
      } else {
     
          axios.post(`https://api.ig2moon.com/checkUser`,{token:token})
          .then(function (response) {
            if(response.data.name !== "JWSSignatureVerificationFailed"){
                setLoginUser(response.data)
                if(response.data.subscriptionExpiry){
                  history.push("/home")
                }

            }else{
                localStorage.removeItem('token')
            }
          })
          .catch(function (error) {
              console.log(error);
          })
      }
  
      // eslint-disable-next-line
  },[])



  let subheading = "Pricing"
  let heading = "Flexible Plans."
  let description = "We have various options to meet the requirements of all the people who wish to grow their Instagram. "
  let plans = null
  let primaryButtonText = "Buy Now"
  const defaultPlans = [
    {
      name: "Half-Monthly",
      price: "₹349/-",
      duration: "15 Days",
      mainFeature: "Suited for new users",
      features: ["6 Rounds Per Day", "75+ Users per round", "15 Days validity"],
    },
    
    {
      name: "Monthly",
      price: "₹549/-",
      duration: "30 Days",
      mainFeature: "Suited for experienced users",
      features: ["6 Rounds Per Day", "75+ Users per round", "30 Days validity"],
    },
  ];

  

	// async function displayRazorpay(planNo) {
	// 	const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

	// 	if (!res) {
	// 		alert('Razorpay SDK failed to load. Are you online?')
	// 		return
	// 	}

	// 	const data = await fetch(`https://api.ig2moon.com/razorpay/${planNo}`, { method: 'POST' }).then((t) =>
	// 		t.json()
	// 	)

	// 	const options = {
  //     key: process.env.REACT_APP_APIKEY,
	// 		currency: data.currency,
	// 		amount: data.amount.toString(),
	// 		order_id: data.id,
	// 		name:planNo===1?'Half-Monthly':'Monthly',
	// 		description: user.name,
	// 		image:logo,
	// 		handler: function (response) {


  //     var redirect_url=""
      
  //     if (typeof response.razorpay_payment_id == 'undefined' ||  response.razorpay_payment_id < 1) {
  //       redirect_url = '/#/login';
  //     } else {
  //       redirect_url = '/#/login';
  //     }
  //     window.location.href = redirect_url;
   
	// 		},
	// 		prefill: {
	// 			name: user.name,
	// 			email: user.email,
	// 		}
	// 	}
	// 	const paymentObject = new window.Razorpay(options)
	// 	paymentObject.open()
	// }

  function isDate(val) {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === '[object Date]'
  }
  
  function isObj(val) {
    return typeof val === 'object'
  }
  
   function stringifyValue(val) {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val)
    } else {
      return val
    }
  }
  
  function buildForm({ action, params }) {
    const form = document.createElement('form')
    form.setAttribute('method', 'post')
    form.setAttribute('action', action)
  
    Object.keys(params).forEach(key => {
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', key)
      input.setAttribute('value', stringifyValue(params[key]))
      form.appendChild(input)
    })
    return form
  }
  
   function post(details) {
    const form = buildForm(details)
    document.body.appendChild(form)
    form.submit()
    form.remove()
    
  }


  const getData=(data)=>
  {

    return fetch(`https://api.ig2moon.com/paytmapi/payment`,{
        method:"POST",
        headers:{
            Accept:"application/json",
            "Content-Type":"application/json"
        },
        body:JSON.stringify(data)
    }).then(response=>response.json()).catch(err=>console.log(err))
  }

  function makePayment(planNo)
  {
    getData({amount:planNo,email:user.email}).then(response=>{
      
      var information={
          action:"https://securegw-stage.paytm.in/order/process",
          params:response
      }
    post(information)

    })
  }




  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }
  

  if (!plans) plans = defaultPlans;

  const highlightGradientsCss = [
    css`
      background: rgb(56, 178, 172);
      background: linear-gradient(115deg, rgba(56, 178, 172, 1) 0%, rgba(129, 230, 217, 1) 100%);
    `,
    css`
      background: rgb(56, 178, 172);
      background-image: linear-gradient(115deg, #6415ff, #7431ff, #8244ff, #8e56ff, #9a66ff);
    `,
    css`
      background: rgb(245, 101, 101);
      background: linear-gradient(115deg, rgba(245, 101, 101, 1) 0%, rgba(254, 178, 178, 1) 100%);
    `
  ];
  
  return(
    <Container>
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              {!plan.featured && <div className="planHighlight" css={highlightGradientsCss[index % highlightGradientsCss.length]} />}
              <PlanHeader>
                <span className="name">{plan.name}</span>
                <span className="price">{plan.price}</span>
                <span className="duration">{plan.duration}</span>
              </PlanHeader>
              <PlanFeatures>
                <span className="feature mainFeature">{plan.mainFeature}</span>
                {plan.features.map((feature, index) => (
                  <span key={index} className="feature">
                    {feature}
                  </span>
                ))}
              </PlanFeatures>
              <ToastContainer
                    position="top-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
              />
              <PlanAction>
                
                { 
                JSON.stringify(user) !== "{}"
                  ? 
                    <BuyNowButton onClick={()=>makePayment(index+1)} css={!plan.featured && highlightGradientsCss[index]}>{primaryButtonText}</BuyNowButton> 
                  : 
                    <BuyNowButton onClick={()=>{
                      toast.error('Login to buy a plan!', {
                        position: "top-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        });
                        setTimeout(() => {
                          history.push('/login')
                        }, 5000);
                        

                    }} css={!plan.featured && highlightGradientsCss[index]}>{primaryButtonText}</BuyNowButton>
                }
                {/* toast('Please create an account to buy a plan!', {position: "top-left",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,}) */}
              </PlanAction>
            </Plan>
          ))}
          <DecoratorBlob/>
        </PlansContainer>
      </ContentWithPaddingXl>
    </Container>
  )
}

export default Threeplan
