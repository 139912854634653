import React,{useState} from 'react'
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import illustration from "images/login-illustration.svg";
import logo from "images/logo.svg";

import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
// import { useHistory } from 'react-router-dom'
import axios from 'axios'
import HashLoader from "react-spinners/HashLoader";

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-16 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;



const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 text-2xl px-2  inline-block my-3`;
  
function ForgotPassword() {
	const [name, setName] = useState('')
  const [otp, setOTP] = useState('')
	const [password, setPassword] = useState('')
	// const history = useHistory()
  const [errMsg,seterrMsg]=useState(null)
  const [loading,setLoading]=useState(false)
  // const [verified,setVerified]=useState(null)

  let logoLinkUrl = "#"
  let illustrationImageSrc = illustration
  let headingText = "Reset Password: "

  // let submitButtonText = "Sign In"
  let SubmitButtonIcon = LoginIcon
  // let forgotPasswordUrl = "#"
  let signinUrl = "#/login"


  function resetPass(){
    setLoading(true)
        axios.post(`https://api.ig2moon.com/resetPass`,{OTP:otp,user_name:name, password: password})
          .then(function (response) {
            setLoading(false)
             if (response.data.status === "ok"){
              toastNotify("Password Reset")
              seterrMsg()
              // LOGIN PUSH
              
             }else{
              seterrMsg("Invalid OTP")
             }
          })
          .catch(function (error) {
              console.log(error);
          })
  }

  const toastNotify = (valueText)=>toast(valueText, {
    position: "top-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });


  function generateOTP(){
    setLoading(true)

    var code = "1234"
    axios.post(`https://api.ig2moon.com/generateOTP`,{OTP:code,user_name:name})
      .then(function (response) {
            setLoading(false)
            // seterrMsg("OTP Sent")
            toastNotify("OTP Sent")
      })
      .catch(function (error) {
              console.log(error);
      })
  }


	return (
    loading ? (<div className='HashLoaderClass'><HashLoader color={"A336D7"} loading={loading} size={30} /></div>):
    <>

    <AnimationRevealPage>
      {       
        <Container>
          <Content>
            <MainContainer>
            <ToastContainer />
              <LogoLink href={logoLinkUrl}>
                <LogoImage src={logo} />
              </LogoLink>
              <MainContent>
                <Heading>{headingText}<HighlightedText>IG2MOON</HighlightedText></Heading>
                <FormContainer>
                  <Form>
                    <Input type="text" placeholder="Username" value={name} onChange={(e) => setName(e.target.value)} required/>
                    
                    <SubmitButton onClick={()=>generateOTP()}>
                      <SubmitButtonIcon className="icon" />
                      <span className="text">Send OTP</span>
                    </SubmitButton>
                    {/* <br />
                    <br />
                    <button className='ActiveRound' style={{margin:'0 auto'}} onClick={()=>generateOTP()}>Send OTP</button> */}
                    
                    <Input type="otp" placeholder="OTP" value={otp} onChange={(e) => setOTP(e.target.value)} required/>
                    <Input type="password" placeholder="New Password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
                    
                    <SubmitButton onClick={()=>resetPass()}>
                      <SubmitButtonIcon className="icon" />
                      <span className="text">Reset Password</span>
                    </SubmitButton>
                    {/* <br />
                    <br />
                    <button className='ActiveRound' onClick={()=>resetPass()}>Reset Password</button> */}

                  </Form>
                  <p tw="mt-6 text-xs text-gray-600 text-center">
                    
                  </p>
                  {errMsg && <><p className='ERRMSG'>{errMsg}<br/></p></>}
                  <p tw="mt-8 text-sm text-gray-600 text-center">
                    Do you have an account?{" "}
                    <a href={signinUrl} tw="border-b border-gray-500 border-dotted">
                      Login
                    </a>
                  </p>
                </FormContainer>
              </MainContent>
            </MainContainer>
            <IllustrationContainer>
              <IllustrationImage imageSrc={illustrationImageSrc} />
            </IllustrationContainer>
          </Content>
        </Container>
      }
      
    </AnimationRevealPage>
    </>
	)
}
export default ForgotPassword